import {
  faPen,
  faPlus,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { apiUrl } from "../../config";
import { toast } from "react-toastify";
import { StaticDialog } from "react-st-modal";
import ImageMarker from "../ImageMarker";

class Adcs extends Component {
  constructor(props) {
    super(props);
    // Define o estado inicial
    this.state = {
      grupo: [],
      delete: false,
      item: undefined,
      textList: [],
      modal: {},
      psq: "",
      ntext: "",
      id: null,
      edit: {},
      trash: {},
    };
  }

  // Método para incrementar o contador
  handleClick = () => {
    this.setState((prevState) => ({ count: prevState.count + 1 }));
  };

  componentDidMount() {
    const matchedItem = this.props.data.find(
      (item) => item.code === this.props.code
    );

    if (matchedItem != undefined) {
      this.setState({ grupo: matchedItem.itens });
    } else {
      this.setState({ grupo: [] });
    }
  }

  handleChange2() {
    var { id, index, ntext, type } = this.state.modal;

    console.log(id);
    if (ntext.trim().length == 0) {
      //event.target.value = '';
    } else {
      fetch(`${apiUrl}/update-apc2/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
          text: ntext,
          type,
        }),
      }).then((response) => {
        toast.success("Sucesso!");

        response.json().then((data) => {
          this.setState((prevState) => {
            const novoGrupo2 = [...prevState.grupo];

            novoGrupo2[index][type] = ntext;

            return { grupo: novoGrupo2, modal: {} };
          });
        });
      });
    }
  }

  handleChange(event, id, type, index) {
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;
      if (!fileType.startsWith("image/")) {
        toast.warn("Por favor, selecione uma imagem válida!");
        event.target.value = null;
      } else {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("type", type);
        formData.append("imagem", file);

        fetch(`${apiUrl}/img-apc2`, {
          method: "POST",
          body: formData,
        }).then((response) => {
          if (response.status == 200) {
            toast.success("Sucesso!");
            response.json().then((data) => {
              this.setState((prevState) => {
                const novoGrupo2 = [...prevState.grupo];

                novoGrupo2[index][type] = data.img;

                return { grupo: novoGrupo2 };
              });
            });
          }
        });
      }
    }
  }

  render() {
    const listaFiltrada = this.state.textList.filter((item) =>
      item.text.toLowerCase().includes(this.state.psq.toLowerCase())
    );

    if (window.location.pathname.includes("/solicitante/preview/") == false) {
      return (
        <>
          <div style={{ marginTop: 15 }}>
            <div style={{ margin: 0 }}>
              <div className="mb-3 stc-a"></div>
              {this.state.grupo.length > 0 ? (
                <div className="img-al3">
                  {this.state.grupo.map((item, index) => (
                    <>
                      {item.type == 0 ? (
                        <>
                          {item.text != "" && (
                            <>
                              <span>{item.text}</span>
                            </>
                          )}

                          {index == 0 && <div style={{ height: 10 }}></div>}
                        </>
                      ) : (
                        <>
                          {item.img1 != null && (
                            <>
                              <img
                                src={`${apiUrl}/img/${item.img1}`}
                                style={{
                                  width: 225,
                                  height: 225,
                                  border: "solid 1px",
                                }}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div class="card" code={this.props.code}>
          <div class="card-header d-flex justify-content-between">
            <h3>SITUAÇÃO ATUAL: - {this.props.code}</h3>
            <div class="custom-file">
              {/*  <button
                class="btn btn-primary btn-upl"
                disabled={
                  this.state.grupo.filter((item) => item.type === 0).length ==
                  this.props.txt
                    ? true
                    : false
                }
                onClick={() => {
                  fetch(
                    `${apiUrl}/add-apc2/${
                      window.location.pathname.split("/")[3]
                    }`,
                    {
                      method: "POST",
                      credentials: "include",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        type: 0,
                        code: this.props.code,
                      }),
                    }
                  ).then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        toast.success("Item adicionado!");
                        this.setState((prevState) => ({
                          grupo: [
                            ...prevState.grupo,
                            { id: data.id, type: 0, text: null }, // Adiciona um novo item com imgs como array vazio
                          ],
                        }));
                      });
                    }
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> Texto
              </button> */}
            </div>
          </div>

          {this.state.grupo.length > 0 ? (
            <div className="card-body">
              {this.state.grupo.map((item, index) => (
                <div className="card mb-3">
                  <div className="card-header d-flex justify-content-between">
                    <h3>Grupo: {index + 1}</h3>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => {
                        this.setState({ delete: true, item });
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                  <div className="card-body">
                    {this.props.onlyText == true && index == 0 ? (
                      <>
                        <div
                          style={{
                            padding: 10,
                            height: "100%",
                            overflowX: "auto",
                            border: "solid 1px #ccc",
                            borderRadius: 5,
                            cursor: "pointer",
                            marginBottom: 20,
                          }}
                          onClick={() => {
                            fetch(`${apiUrl}/text-list`, {
                              method: "GET",
                              credentials: "include",
                              headers: {
                                "Content-Type": "application/json",
                              },
                            }).then((response) => {
                              if (response.status == 200) {
                                response.json().then((data) => {
                                  this.setState({ textList: data });
                                });
                              }
                            });

                            this.setState({
                              modal: {
                                ntext: item.text == undefined ? "" : item.text,
                                id: item.id,
                                index,
                                type: "text",
                              },
                            });
                          }}
                        >
                          {item.text == null ? "Digite:" : item.text}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            padding: 10,
                            height: "100%",
                            overflowX: "auto",
                            border: "solid 1px #ccc",
                            borderRadius: 5,
                            cursor: "pointer",
                            marginBottom: 20,
                          }}
                          onClick={() => {
                            fetch(`${apiUrl}/text-list`, {
                              method: "GET",
                              credentials: "include",
                              headers: {
                                "Content-Type": "application/json",
                              },
                            }).then((response) => {
                              if (response.status == 200) {
                                response.json().then((data) => {
                                  this.setState({ textList: data });
                                });
                              }
                            });

                            this.setState({
                              modal: {
                                ntext: item.text == undefined ? "" : item.text,
                                id: item.id,
                                index,
                                type: "text",
                              },
                            });
                          }}
                        >
                          {item.text == null ? "Digite:" : item.text}
                        </div>

                        <div className="d-flex">
                          <div class="area-8">
                            {item.img1 != null ? (
                              <>
                                <div className="area-8 gp-imgs">
                                  <div class="view">
                                    {/*  <div class="edit">
                <input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img1',index)
}}/>
    <FontAwesomeIcon icon={faPen} color='#fff'/>
                </div>*/}
                                    <img src={`${apiUrl}/img/${item.img1}`} />
                                    <div class="ac">
                                      <div class="arb">
                                        <button
                                          class="b1"
                                          onClick={() => {
                                            this.setState({
                                              edit: {
                                                id: item.id,
                                                index,
                                                img: item.img1,
                                                ref: item.ref,
                                                type: "img1",
                                              },
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPen}
                                            color="#fff"
                                          />
                                        </button>
                                        <button
                                          class="b2"
                                          onClick={() => {
                                            this.setState({
                                              trash: {
                                                id: item.id,
                                                type: "img1",
                                                index,
                                              },
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrash}
                                            color="#fff"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div class="add">
                                  <input
                                    type="file"
                                    onChange={(event) => {
                                      this.handleChange(
                                        event,
                                        item.id,
                                        "img1",
                                        index
                                      );
                                    }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    color="#282828"
                                  />{" "}
                                </div>
                              </>
                            )}
                          </div>

                          <div class="area-8">
                            {item.img2 != null ? (
                              <>
                                <div className="area-8 gp-imgs">
                                  <div class="view">
                                    {/*  <div class="edit">
                <input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img1',index)
}}/>
    <FontAwesomeIcon icon={faPen} color='#fff'/>
                </div>*/}
                                    <img src={`${apiUrl}/img/${item.img2}`} />
                                    <div class="ac">
                                      <div class="arb">
                                        <button
                                          class="b1"
                                          onClick={() => {
                                            this.setState({
                                              edit: {
                                                id: item.id,
                                                index,
                                                img: item.img2,
                                                ref: item.ref,
                                                type: "img2",
                                              },
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPen}
                                            color="#fff"
                                          />
                                        </button>
                                        <button
                                          class="b2"
                                          onClick={() => {
                                            this.setState({
                                              trash: {
                                                id: item.id,
                                                type: "img2",
                                                index,
                                              },
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrash}
                                            color="#fff"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div class="add">
                                  <input
                                    type="file"
                                    onChange={(event) => {
                                      this.handleChange(
                                        event,
                                        item.id,
                                        "img2",
                                        index
                                      );
                                    }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    color="#282828"
                                  />{" "}
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        <div>
                          {this.props.exs == false ? (
                            <div class="mt-3">
                              {/*          <div
                            style={{
                              padding: 10,
                              height: "100%",
                              overflowX: "auto",
                              border: "solid 1px #ccc",
                              borderRadius: 5,
                              cursor: "pointer",
                              marginBottom: 20,
                            }}
                            onClick={() => {
                              fetch(`${apiUrl}/text-list`, {
                                method: "GET",
                                credentials: "include",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                              }).then((response) => {
                                if (response.status == 200) {
                                  response.json().then((data) => {
                                    this.setState({
                                      textList: data,
                                    });
                                  });
                                }
                              });

                              this.setState({
                                modal: {
                                  ntext: item.text2,
                                  index,
                                  id: item.id,
                                  type: "text2",
                                },
                              });
                            }}
                          >
                            {item.text2 == null ? "Digite:" : item.text2}
                          </div>*/}
                              <div className="d-flex">
                                <div class="area-8">
                                  {item.img3 != null ? (
                                    <>
                                      <div className="area-8 gp-imgs">
                                        <div class="view">
                                          {/*  <div class="edit">
<input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img1',index)
}}/>
<FontAwesomeIcon icon={faPen} color='#fff'/>
</div>*/}
                                          <img
                                            src={`${apiUrl}/img/${item.img3}`}
                                          />
                                          <div class="ac">
                                            <div class="arb">
                                              <button
                                                class="b1"
                                                onClick={() => {
                                                  console.log(item);
                                                  this.setState({
                                                    edit: {
                                                      id: item.id,
                                                      index,
                                                      img: item.img3,
                                                      ref: item.ref,
                                                      type: "img3",
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faPen}
                                                  color="#fff"
                                                />
                                              </button>
                                              <button
                                                class="b2"
                                                onClick={() => {
                                                  this.setState({
                                                    trash: {
                                                      id: item.id,
                                                      index,
                                                      type: "img3",
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faTrash}
                                                  color="#fff"
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div class="add">
                                        <input
                                          type="file"
                                          onChange={(event) => {
                                            this.handleChange(
                                              event,
                                              item.id,
                                              "img3",
                                              index
                                            );
                                          }}
                                        />
                                        <FontAwesomeIcon
                                          icon={faPlus}
                                          color="#282828"
                                        />{" "}
                                      </div>
                                    </>
                                  )}
                                </div>

                                <div class="area-8">
                                  {item.img4 != null ? (
                                    <>
                                      <div className="area-8 gp-imgs">
                                        <div class="view">
                                          {/*  <div class="edit">
<input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img1',index)
}}/>
<FontAwesomeIcon icon={faPen} color='#fff'/>
</div>*/}
                                          <img
                                            src={`${apiUrl}/img/${item.img4}`}
                                          />
                                          <div class="ac">
                                            <div class="arb">
                                              <button
                                                class="b1"
                                                onClick={() => {
                                                  this.setState({
                                                    edit: {
                                                      id: item.id,
                                                      index,
                                                      img: item.img4,
                                                      ref: item.ref,
                                                      type: "img4",
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faPen}
                                                  color="#fff"
                                                />
                                              </button>
                                              <button
                                                class="b2"
                                                onClick={() => {
                                                  this.setState({
                                                    trash: {
                                                      id: item.id,
                                                      index,
                                                      type: "img4",
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faTrash}
                                                  color="#fff"
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div class="add">
                                        <input
                                          type="file"
                                          onChange={(event) => {
                                            this.handleChange(
                                              event,
                                              item.id,
                                              "img4",
                                              index
                                            );
                                          }}
                                        />
                                        <FontAwesomeIcon
                                          icon={faPlus}
                                          color="#282828"
                                        />{" "}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              {this.props.exs == true &&
                                this.state.grupo.length > 1 && (
                                  <>
                                    {index == this.state.grupo.length - 1 &&
                                    index == 1 ? (
                                      ""
                                    ) : (
                                      <>
                                        {index > 1 &&
                                        index == this.state.grupo.length - 1 ? (
                                          ""
                                        ) : (
                                          <>
                                            <div
                                              style={{
                                                padding: 10,
                                                height: "100%",
                                                overflowX: "auto",
                                                border: "solid 1px #ccc",
                                                borderRadius: 5,
                                                cursor: "pointer",
                                                marginTop: 20,
                                              }}
                                              onClick={() => {
                                                fetch(`${apiUrl}/text-list`, {
                                                  method: "GET",
                                                  credentials: "include",
                                                  headers: {
                                                    "Content-Type":
                                                      "application/json",
                                                  },
                                                }).then((response) => {
                                                  if (response.status == 200) {
                                                    response
                                                      .json()
                                                      .then((data) => {
                                                        this.setState({
                                                          textList: data,
                                                        });
                                                      });
                                                  }
                                                });

                                                this.setState({
                                                  modal: {
                                                    ntext: item.text2,
                                                    index,
                                                    id: item.id,
                                                    type: "text2",
                                                  },
                                                });
                                              }}
                                            >
                                              {item.text2 == null
                                                ? "Digite:"
                                                : item.text2}
                                            </div>
                                            <div className="d-flex">
                                              <div class="area-8">
                                                {item.img3 != null ? (
                                                  <>
                                                    <div className="area-8 gp-imgs">
                                                      <div class="view">
                                                        {/*  <div class="edit">
                <input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img1',index)
}}/>
    <FontAwesomeIcon icon={faPen} color='#fff'/>
                </div>*/}
                                                        <img
                                                          src={`${apiUrl}/img/${item.img3}`}
                                                        />
                                                        <div class="ac">
                                                          <div class="arb">
                                                            <button
                                                              class="b1"
                                                              onClick={() => {
                                                                this.setState({
                                                                  edit: {
                                                                    id: item.id,
                                                                    index,
                                                                    img: item.img3,
                                                                    ref: item.ref,
                                                                    type: "img3",
                                                                  },
                                                                });
                                                              }}
                                                            >
                                                              <FontAwesomeIcon
                                                                icon={faPen}
                                                                color="#fff"
                                                              />
                                                            </button>
                                                            <button
                                                              class="b2"
                                                              onClick={() => {
                                                                this.setState({
                                                                  trash: {
                                                                    id: item.id,
                                                                    index,
                                                                    type: "img3",
                                                                  },
                                                                });
                                                              }}
                                                            >
                                                              <FontAwesomeIcon
                                                                icon={faTrash}
                                                                color="#fff"
                                                              />
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div class="add">
                                                      <input
                                                        type="file"
                                                        onChange={(event) => {
                                                          this.handleChange(
                                                            event,
                                                            item.id,
                                                            "img3",
                                                            index
                                                          );
                                                        }}
                                                      />
                                                      <FontAwesomeIcon
                                                        icon={faPlus}
                                                        color="#282828"
                                                      />{" "}
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                              <div class="area-8">
                                                {item.img4 != null ? (
                                                  <>
                                                    <div className="area-8 gp-imgs">
                                                      <div class="view">
                                                        {/*  <div class="edit">
                <input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img1',index)
}}/>
    <FontAwesomeIcon icon={faPen} color='#fff'/>
                </div>*/}
                                                        <img
                                                          src={`${apiUrl}/img/${item.img4}`}
                                                        />
                                                        <div class="ac">
                                                          <div class="arb">
                                                            <button
                                                              class="b1"
                                                              onClick={() => {
                                                                this.setState({
                                                                  edit: {
                                                                    id: item.id,
                                                                    index,
                                                                    img: item.img4,
                                                                    ref: item.ref,
                                                                    type: "img4",
                                                                  },
                                                                });
                                                              }}
                                                            >
                                                              <FontAwesomeIcon
                                                                icon={faPen}
                                                                color="#fff"
                                                              />
                                                            </button>
                                                            <button
                                                              class="b2"
                                                              onClick={() => {
                                                                this.setState({
                                                                  trash: {
                                                                    id: item.id,
                                                                    index,
                                                                    type: "img4",
                                                                  },
                                                                });
                                                              }}
                                                            >
                                                              <FontAwesomeIcon
                                                                icon={faTrash}
                                                                color="#fff"
                                                              />
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div class="add">
                                                      <input
                                                        type="file"
                                                        onChange={(event) => {
                                                          this.handleChange(
                                                            event,
                                                            item.id,
                                                            "img4",
                                                            index
                                                          );
                                                        }}
                                                      />
                                                      <FontAwesomeIcon
                                                        icon={faPlus}
                                                        color="#282828"
                                                      />{" "}
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-center">
                <button
                  class="btn btn-primary btn-upl"
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    fetch(
                      `${apiUrl}/add-apc2/${
                        window.location.pathname.split("/")[3]
                      }`,
                      {
                        method: "POST",
                        credentials: "include",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          type: 1,
                          code: this.props.code,
                        }),
                      }
                    ).then((response) => {
                      if (response.status == 200) {
                        response.json().then((data) => {
                          toast.success("Item adicionado!");
                          this.setState((prevState) => ({
                            grupo: [
                              ...prevState.grupo,
                              { id: data.id, type: 1, imgs: [] }, // Adiciona um novo item com imgs como array vazio
                            ],
                          }));
                        });
                      }
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Grupo
                </button>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center mt-3 mb-3">
              <button
                class="btn btn-primary btn-upl"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  fetch(
                    `${apiUrl}/add-apc2/${
                      window.location.pathname.split("/")[3]
                    }`,
                    {
                      method: "POST",
                      credentials: "include",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        type: 1,
                        code: this.props.code,
                      }),
                    }
                  ).then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        toast.success("Item adicionado!");
                        this.setState((prevState) => ({
                          grupo: [
                            ...prevState.grupo,
                            { id: data.id, type: 1, imgs: [] }, // Adiciona um novo item com imgs como array vazio
                          ],
                        }));
                      });
                    }
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> Grupo
              </button>
            </div>
          )}
        </div>

        <StaticDialog
          isOpen={Object.keys(this.state.edit).length > 0}
          title="TEXTO:"
          onAfterClose={(result) => {
            this.setState({ edit: {} });
          }}
        >
          <ImageMarker
            code={this.props.code}
            imageSrc={`${apiUrl}/img/${this.state.edit.img}`}
            isOpen={Object.keys(this.state.edit).length > 0}
            onUpload={(blob) => {
              const formData = new FormData();
              formData.append("type", this.state.edit.type);
              formData.append("id", this.state.edit.id);
              formData.append("imagem", blob, "update.png");
              fetch(`${apiUrl}/img-apc2`, {
                method: "POST",
                body: formData,
              }).then((response) => {
                toast.success("Imagem editada com sucesso!");

                response.json().then((data) => {
                  this.setState((prevState) => {
                    const novoGrupo2 = [...prevState.grupo];

                    novoGrupo2[this.state.edit.index][this.state.edit.type] =
                      data.img;

                    return { grupo: novoGrupo2 };
                  });

                  this.setState({ edit: "" });
                });
              });
            }}
          />
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.modal).length > 0}
          title="TEXTO:"
          onAfterClose={(result) => {
            this.setState({ modal: {} });
          }}
        >
          <div style={{ padding: 10 }}>
            <div>
              <textarea
                class="form-control form-control-sm"
                style={{ height: 100 }}
                maxLength={this.props.maxText || 1000}
                onChange={(e) => {
                  const value = e.target.value;
                  const lines = value.split("\n");

                  this.setState((prevState) => ({
                    modal: {
                      ...prevState.modal, // mantém os valores anteriores de id e type
                      ntext: value, // atualiza apenas a propriedade text
                    },
                  }));
                }}
                value={this.state.modal.ntext || ""}
                placeholder="Digite:"
              ></textarea>
              <div className="ctt" style={{ marginRight: 20 }}>
                {this.state.modal.ntext != undefined &&
                  this.state.modal.ntext.length}
                /{this.props.maxText || 1000}
              </div>
            </div>

            <input
              style={{
                width: "100%",
                height: 35,
                marginTop: 10,
              }}
              type="text"
              onChange={(e) => {
                this.setState({ psq: e.target.value });
              }}
              placeholder="Pesquisar:"
            />
            <div class="area-ptxt">
              {listaFiltrada.length > 0 ? (
                <>
                  <ul>
                    {listaFiltrada.map((item) => (
                      <>
                        <li
                          onClick={() => {
                            this.setState({
                              ntext:
                                this.props.maxText != undefined
                                  ? this.setState((prevState) => ({
                                      modal: {
                                        ...prevState.modal, // mantém os valores anteriores de id e type
                                        ntext: item.text.substring(
                                          0,
                                          this.props.maxText
                                        ), // atualiza apenas a propriedade text
                                      },
                                    }))
                                  : item.text,
                            });
                          }}
                        >
                          {item.text}
                        </li>
                      </>
                    ))}
                  </ul>
                </>
              ) : (
                <>Não há nada aqui!</>
              )}
            </div>
          </div>

          <hr />

          <div
            class="d-flex justify-content-end"
            style={{
              margin: 10,
            }}
          >
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                this.handleChange2();
              }}
            >
              <FontAwesomeIcon icon={faSave} color="#fff" /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Atenção"
          onAfterClose={(result) => {
            this.setState({ trash: {} });
            // do something with dialog result
          }}
        >
          <div style={{ margin: 10 }}>
            Uma vez deletado, não será possível recuperar
          </div>

          <div className="modal-btn">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");

                fetch(`${apiUrl}/del-img-apc2/`, {
                  method: "POST",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: this.state.trash.id,
                    type: this.state.trash.type,
                  }),
                }).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status == 200) {
                    toast.success("Imagem removida com sucesso!");
                    this.setState((prevState) => {
                      const novoGrupo2 = [...prevState.grupo];

                      novoGrupo2[this.state.trash.index][
                        this.state.trash.type
                      ] = null;

                      return { grupo: novoGrupo2 };
                    });
                    this.setState({ trash: {} });
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faTrash} color="#fff" /> Remover
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.delete}
          title="Atenção"
          onAfterClose={(result) => {
            this.setState({ delete: false, item: {} });
            // do something with dialog result
          }}
        >
          <div style={{ margin: 10 }}>
            Uma vez deletado, não será possível recuperar
          </div>
          <div className="modal-btn">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                var id = this.state.item.id;

                fetch(`${apiUrl}/remove-apc2/${id}`, {
                  method: "DELETE",
                })
                  .then((response) => {
                    if (response.status == 200) {
                      e.target.classList.remove("load");
                      toast.success("Sucesso!");
                      this.setState((prevState) => ({
                        grupo: prevState.grupo.filter((item) => item.id !== id),
                        delete: false,
                      }));
                    }
                  })
                  .catch(() => {
                    e.classList.remove("load");
                    toast.error("Falha na comunicação!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} color="#fff" /> Remover
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default Adcs;
