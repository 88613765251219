import React from "react";
import { Route, Routes, Router, useNavigate } from "react-router-dom";
import "../src/paginas/Login/l.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";
import Login from "./paginas/Login";
import Logado from "./paginas/Logado";
import Header from "./Comps/Header";
import Cotacao from "./paginas/Cotacao";
import CreateCot from "./paginas/Cotacao/CreateCot";
import PrevCot from "./paginas/Cotacao/PrevCot";
import ImportCot from "./paginas/Cotacao/ImportCot";
import EditCot from "./paginas/Cotacao/EditCot";
import CadastroCli from "./paginas/CadastroCli";
import Clientes from "./paginas/CadastroCli/Clientes";
import InfoCliente from "./paginas/CadastroCli/InfoCliente";
import EditCliente from "./paginas/CadastroCli/EditCliente";
import CotacaoList from "./paginas/Cotacao/CotacaoList";
import FreteInternacional from "./paginas/FreteInternacional";
import CustoDeOrigem from "./paginas/Cotacao/CustoDeOrigem";
import CustoDeDestino from "./paginas/Cotacao/CustoDeDestino";
import CadastroExecutante from "./paginas/CadastroExecutante";
import CreateExect from "./paginas/CreateExect";
import PrevExe from "./paginas/PrevExe";
import EditExec from "./paginas/EditExec";
import Teste from "./paginas/Teste";
import Normas from "./paginas/Normas";
import EditNorma from "./paginas/Normas/EditNorma";
import AdicionarNorma from "./paginas/Normas/AdicionarNorma";
import Tecnicos from "./paginas/Tecnicos";
import AdicionarTec from "./paginas/Tecnicos/AdicionarTec";
import EditTec from "./paginas/Tecnicos/EditTec";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditNormaDoc from "./paginas/Normas/EditNormaDoc";
import EditTecnicoDoc from "./paginas/Tecnicos/EditTecnicoDoc";
import { CustomDialog, useDialog } from "react-st-modal";
import PreView from "./paginas/PreView";
import Galeria from "./paginas/Galeria";
import LaudoTecnico from "./paginas/LaudoTecnico";
import CreateLau from "./paginas/Cotacao/CreateLau";
import LaudoList from "./paginas/LaudoList";
import PrevLau from "./paginas/PrevLau";
import Textos from "./paginas/Texto";
import AdicionarTex from "./paginas/Tecnicos/AdicionarTex";
import EditTex from "./paginas/Tecnicos/EditTex";
import PreviewLau from "./paginas/PreviewLau";
import AdrView from "./paginas/AdrView";
import Preview2 from "./paginas/Preview2";
import GerFiles from "./paginas/GerFiles";
function App() {
  const navigateTo = useNavigate();
  const dialog = useDialog();
  return (
    <>
      {Cookies.get("auth_token") != undefined ? (
        <>
          <Header />
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Cotacao navigate={navigateTo} />} />
            <Route
              path="/laudo-tecnico"
              element={<LaudoTecnico navigate={navigateTo} />}
            />

            <Route
              path="/solicitante/:token/list"
              element={<CotacaoList navigate={navigateTo} />}
            />
            <Route
              path="/laudo/:token/list"
              element={<LaudoList navigate={navigateTo} />}
            />

            <Route
              path="/criar-documento/:token"
              element={<CreateCot navigate={navigateTo} />}
            />
            <Route
              path="/criar-laudo/:token"
              element={<CreateLau navigate={navigateTo} />}
            />

            <Route
              path="/solicitante/preview/:token"
              element={<PrevCot dialog={dialog} navigate={navigateTo} />}
            />
            <Route
              path="/solicitante/preview/:token/files"
              element={<GerFiles dialog={dialog} navigate={navigateTo} />}
            />

            <Route
              path="/laudo-tecnico/preview/:token"
              element={<PrevLau dialog={dialog} navigate={navigateTo} />}
            />

            <Route
              path="/cotacao/importar/:token"
              element={<ImportCot navigate={navigateTo} />}
            />
            <Route
              path="/galeria"
              element={<Galeria navigate={navigateTo} />}
            />
            <Route
              path="/solicitante/edit/:token"
              element={<EditCot navigate={navigateTo} />}
            />
            <Route
              path="/cotacao/frete-internacional/:token"
              element={<FreteInternacional navigate={navigateTo} />}
            />
            <Route
              path="/cotacao/custo-de-origem/:token"
              element={<CustoDeOrigem navigate={navigateTo} />}
            />
            <Route
              path="/cotacao/custo-de-destino/:token"
              element={<CustoDeDestino navigate={navigateTo} />}
            />

            <Route
              path="/cadastro-solicitante"
              element={<Clientes navigate={navigateTo} />}
            />
            <Route
              path="/cadastro-solicitante/adicionar"
              element={<CadastroCli navigate={navigateTo} />}
            />
            <Route
              path="/cadastro-solicitante/preview/:token"
              element={<InfoCliente navigate={navigateTo} />}
            />
            <Route
              path="/cadastro-solicitante/edit/:token"
              element={<EditCliente navigate={navigateTo} />}
            />

            <Route
              path="/cadastro-executante"
              element={<CadastroExecutante navigate={navigateTo} />}
            />
            <Route
              path="/cadastro-executante/adicionar"
              element={<CreateExect navigate={navigateTo} />}
            />
            <Route
              path="/cadastro-executante/preview/:token"
              element={<PrevExe navigate={navigateTo} />}
            />
            <Route
              path="/cadastro-executante/edit/:token"
              element={<EditExec navigate={navigateTo} />}
            />

            <Route path="/normas" element={<Normas navigate={navigateTo} />} />
            <Route
              path="/normas/edit/:id"
              element={<EditNorma navigate={navigateTo} />}
            />
            <Route
              path="/normas/editar/doc/:token/:id"
              element={<EditNormaDoc navigate={navigateTo} />}
            />
            <Route
              path="/normas/adicionar"
              element={<AdicionarNorma navigate={navigateTo} />}
            />

            <Route
              path="/tecnicos"
              element={<Tecnicos navigate={navigateTo} />}
            />
            <Route
              path="/tecnicos/edit/:id"
              element={<EditTec navigate={navigateTo} />}
            />
            <Route
              path="/tecnicos/editar/doc/:token/:id"
              element={<EditTecnicoDoc navigate={navigateTo} />}
            />
            <Route
              path="/tecnicos/adicionar"
              element={<AdicionarTec navigate={navigateTo} />}
            />

            <Route path="/textos" element={<Textos navigate={navigateTo} />} />
            <Route
              path="/textos/adicionar"
              element={<AdicionarTex navigate={navigateTo} />}
            />
            <Route
              path="/textos/edit/:id"
              element={<EditTex navigate={navigateTo} />}
            />
          </Routes>
          <ToastContainer />
        </>
      ) : (
        <>
          <Routes>
            <Route exact path="/view/:token" element={<Teste />} />
            <Route exact path="/preview/:token" element={<AdrView />} />
            <Route exact path="/preview2/:token" element={<Preview2 />} />
            <Route exact path="/previewLau/:token" element={<PreviewLau />} />
            <Route exact path="/" element={<Login />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
