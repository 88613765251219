import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faDownload,
  faPen,
  faArrowLeft,
  faAngleLeft,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { apiUrl } from "../../../config";
import InputMask from "react-input-mask";
import p404 from "../../../images/undraw_text_files_au1q.png";
class EditCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      error: false,
      cadastro_empresa: [],
      endereco_brasil: [],
    };
  }
  handleFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  componentDidMount() {
    document.title = "Informações básicas";
    fetch(`${apiUrl}/empresas/${window.location.pathname.split("/")[3]}/list`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ cadastro_empresa: data, Loader: false });
        });
      }
      if (response.status == 404) {
        this.setState({ error: true, Loader: false });
      }
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      cadastro_empresa: {
        ...prevState.cadastro_empresa,
        [name]: value,
      },
    }));
  };

  handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/webp"];
      if (!validImageTypes.includes(file.type)) {
        toast.warn("Por favor, selecione uma imagem válida!");
        return;
      }

      // Cria o FormData para envio
      const formData = new FormData();
      formData.append("image", file);

      fetch(
        `${apiUrl}/empresas/${window.location.pathname.split("/")[3]}/upload`,
        {
          method: "POST",
          body: formData,
        }
      ).then((response) => {
        if (response.status == 200) {
          response.json().then((data) => {
            this.setState({
              cadastro_empresa: {
                ...this.state.cadastro_empresa,
                logo: data.outputFileName,
              },
            });
          });
          toast.success("Imagem enviada com sucesso!");
        }
      });
    }
  };

  render() {
    const { cadastro_empresa } = this.state;
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    if (this.state.error == true) {
      return (
        <>
          <div class="p404">
            <img src={p404} />
            <p>Desculpe, Esse documento não foi encontrado.</p>
            <button
              type="submit"
              class="btn btn-primary float-left ml-2"
              onClick={() => {
                this.props.navigate(
                  `/${window.location.pathname.split("/")[1]}`
                );
              }}
            >
              {" "}
              <FontAwesomeIcon color="#fff" icon={faArrowLeft} /> Início
            </button>
          </div>
        </>
      );
    }
    return (
      <>
        <header class="bg-white shadow">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <button
              class="font-semibold text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} /> {document.title}
            </button>
          </div>
        </header>

        <main>
          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="row">
                  <div class="py-12">
                    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-header d-flex justify-content-between">
                            <h4>DADOS DA EMPRESA</h4>
                          </div>

                          <div class="card-body">
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">Título</th>
                                  <th scope="col">Input</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>RAZÃO SOCIAL:</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Digite o nome completo"
                                      name="razao_social"
                                      value={cadastro_empresa.razao_social}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>CNPJ:</td>
                                  <td>
                                    <InputMask
                                      mask="99.999.999/9999-99"
                                      className="form-control"
                                      placeholder="Digite o CNPJ"
                                      name="cnpj"
                                      value={cadastro_empresa.cnpj}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>LOGO:</td>
                                  <td>
                                    <div className="d-flex align-items-center area-logo">
                                      <img
                                        src={
                                          apiUrl +
                                          "/img/" +
                                          cadastro_empresa.logo
                                        }
                                        style={{ width: 180, height: 65 }}
                                        alt="logo"
                                      />
                                      <div
                                        className="btn"
                                        style={{ marginLeft: "10px" }}
                                      >
                                        <input
                                          id="upload"
                                          type="file"
                                          accept="image/*"
                                          onChange={this.handleFileChange}
                                        />
                                        <button
                                          for="upload"
                                          className="btn btn-primary"
                                        >
                                          <FontAwesomeIcon
                                            color="#fff"
                                            icon={faUpload}
                                          />{" "}
                                          Upload
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>ENDEREÇO:</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Digite o nome endereço"
                                      name="endereco"
                                      value={cadastro_empresa.endereco}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>ESTADO:</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Digite o estado"
                                      name="estado"
                                      value={cadastro_empresa.estado}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>CEP:</td>
                                  <td>
                                    <InputMask
                                      mask="99.999-999"
                                      className="form-control"
                                      placeholder="Digite o cep"
                                      name="cep"
                                      value={cadastro_empresa.cep}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>TELEFONE:</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Digite o telefone"
                                      name="telefone"
                                      value={cadastro_empresa.telefone}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>E-mail:</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Digite o e-mail"
                                      name="email"
                                      value={cadastro_empresa.email}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="card-fotter">
                              <button
                                type="button"
                                class="btn btn-primary float-right ml-2"
                                onClick={() => {
                                  const id = toast.loading("Enviando...");

                                  fetch(
                                    `${apiUrl}/cadastro-cliente/edit/${
                                      window.location.pathname.split("/")[3]
                                    }`,
                                    {
                                      method: "POST",
                                      credentials: "include",
                                      headers: {
                                        "Content-Type": "application/json",
                                        Authorization:
                                          "Bearer " + Cookies.get("auth_token"),
                                      },
                                      body: JSON.stringify({
                                        cadastro_empresa,
                                      }),
                                    }
                                  )
                                    .then((response) => {
                                      if (response.status == 200) {
                                        response.json().then((data) => {
                                          toast.update(id, {
                                            render:
                                              "Cadastro atualizado com sucesso!",
                                            type: "error",
                                            isLoading: false,
                                            autoClose: 3000,
                                          });

                                          this.props.navigate(
                                            `/cadastro-solicitante/preview/${data.token}`
                                          );
                                        });
                                      }
                                      if (response.status == 422) {
                                        response.json().then((data) => {
                                          toast.update(id, {
                                            render: data.error,
                                            type: "error",
                                            isLoading: false,
                                            autoClose: 3000,
                                          });
                                        });
                                      }
                                    })
                                    .catch((err) => {});
                                }}
                              >
                                {" "}
                                <FontAwesomeIcon
                                  color="#fff"
                                  icon={faSave}
                                />{" "}
                                Salvar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <ToastContainer />
      </>
    );
  }
}

export default EditCliente;
